.table-header {
  th {
    background: #f4f7fe;
    padding: 1rem;
    font: var(--contentMediumB);
    text-align: var(--row-align);
    text-transform: capitalize;
    vertical-align: middle;
  }
  th:first-of-type {
    border-radius: 10px 0 0 10px;
  }
  th:last-of-type {
    border-radius: 0 10px 10px 0;
  }
}

.table-container {
  border-collapse: collapse;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  overflow-x: scroll;
}

.table-row {
  color: #666d81;
  border-bottom: 1px solid rgba(102, 109, 129, 0.3);
  td {
    padding: 1rem;
    text-align: var(--row-align);
    font: var(--contentSmall);
  }
}

.container {
  background: #ffffff;
}
.table-no-data-row {
  color: #666d81;
  border-bottom: 1px solid rgba(102, 109, 129, 0.3);
}

.no-data-style {
  text-align: center;
  padding: 20px 10px;
  font: var(--contentMediumB);
}

.main-container {
  overflow-x: scroll;
  // position: relative;
}

.spinner-style {
  position: relative;
}
