.label-style,
.header-style {
  margin-bottom: 5px;
  font: var(--contentMedium);
  text-transform: capitalize;
}

.header-style {
  font: var(--contentLargeB);
  margin: 0.5rem 0 1rem;
  > i {
    vertical-align: bottom;
    font-size: 24px;
  }
}
