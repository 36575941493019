body {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: 'Poppins';
}

code {
  font-family: 'Poppins';
}
body ::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}
body ::-webkit-scrollbar-thumb {
  border-radius: 22px;
  background-color: rgba(177, 177, 177, 0.4);
  height: 16px;
  border: 4.5px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}
body ::-webkit-scrollbar-corner {
  background-color: rgba(0, 0, 0, 0);
}

:root {
  --ui-danger: #e94d31;
  --ui-light: #ffffff;
  --ui-neutral: #77787a;
  --ui-light-neutral: #0000001a;
  --ui-dark: #000000de;
  --ui-blue: #336094;
  --ui-blue_dark: #17306f;
  --ui-primary: #00a7ea;
  --theme-color: #ff6b6b;

  --headingLarge: 400 32px 'Poppins';
  --headingMedium: 400 24px 'Poppins';
  --headingSmall: 400 16px 'Poppins';
  --headingVerySmall: 400 12px 'Poppins';

  --contentExtraLarge: 400 22px 'Poppins';
  --contentLarge: 400 20px 'Poppins';
  --contentMediumBig: 400 18px 'Poppins';
  --contentMedium: 400 16px 'Poppins';
  --contentSmall: 400 14px 'Poppins';
  --contentVerySmall: 400 12px 'Poppins';
  --contentTiny: 400 10px 'Poppins';
  --contentExtraLargeB: 500 22px 'Poppins';
  --contentLargeB: 500 20px 'Poppins';
  --contentMediumBigB: 500 18px 'Poppins';
  --contentMediumB: 500 16px 'Poppins';
  --contentSmallB: 500 14px 'Poppins';
  --contentVerySmallB: 500 12px 'Poppins';
}

html,
body,
div,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
address,
del,
dfn,
em,
img,
ins,
strong,
sub,
sup,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
menu,
nav,
section,
time,
mark,
audio,
video,
details,
summary {
  margin: 0;
  padding: 0;
  border: 0;
  // vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
  color: var(--ui-dark);
}
span {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}
html {
  overflow-y: scroll;
}

ol,
ul {
  list-style: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  color: var(--ui-dark);
  text-decoration: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
th {
  font-weight: bold;
  vertical-align: bottom;
}
td {
  font-weight: normal;
  vertical-align: middle;
}

input,
select {
  vertical-align: middle;
}
input[type='radio'] {
  vertical-align: text-bottom;
}
input[type='checkbox'] {
  vertical-align: bottom;
}

.clickable,
input[type='button'],
input[type='submit'],
input[type='file'],
button {
  cursor: pointer;
}

button,
input,
select,
textarea {
  margin: 0;
}
button,
input[type='button'] {
  width: auto;
  overflow: visible;
}

.clearfix:before,
.clearfix:after {
  content: '\0020';
  display: block;
  height: 0;
  overflow: hidden;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  zoom: 1;
}

select,
input,
textarea,
button {
  font: 100%;
}
