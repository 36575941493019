.container {
  margin-bottom: 10px;
}
.container input {
  outline: none;
  border: 0px;
  border-radius: 10px;
  font: var(--contentMedium);
  font-weight: 400;
  line-height: 16px;
  color: #131d3b;
  width: 100%;
  background: none;
  padding: 0 5px 0 5px;
  min-height: 40px;
}

.container input:disabled {
  color: #131d3b;
  -webkit-text-fill-color: #131d3b;
  opacity: 1;
}
.input-container {
  position: relative;
  border: 1px solid var(--ui-light-neutral);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--ui-light);
  padding: 5px;
  height: 40px;
  margin-bottom: 5px;
}

.input-container[data-disabled='true'] {
  background: rgba(0, 0, 0, 0.2);
}
.container input::placeholder {
  font: var(--contentMedium);
  font-weight: 400;
  line-height: 16px;
  color: var(--ui-neutral);
  text-transform: capitalize;
}

.container i,
.container svg {
  color: var(--ui-neutral);
  cursor: pointer;
  margin: 0 5px;
  font-size: 25px;
  width: 25px;
}

.container svg {
  width: 20px;
  path {
    fill: var(--ui-neutral);
  }
}

.select-container {
  position: relative;
}
.list {
  position: absolute;
  background-color: var(--ui-light);
  box-shadow: 0px 12px 18px rgba(20, 38, 132, 0.1);
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  max-height: 300px;
  overflow-y: scroll;
  z-index: 500;

  &-item,
  &-item-selected {
    cursor: pointer;
    font-weight: 400;
    line-height: 40px;
    list-style: none;
    min-height: 40px;
    vertical-align: middle;
    outline: 0;
    padding-left: 18px;
    padding-right: 29px;
    padding-top: 10px;
    text-align: left;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    text-transform: capitalize;
    font: var(--contentMedium);
    border-bottom: 1px solid var(--ui-light-neutral);
  }
  &-item:hover {
    background: var(--ui-light-neutral);
  }
  &-item-selected {
    background: var(--ui-blue);
    color: var(--ui-light);
    border-bottom: 1px solid var(--ui-light);
  }
}

.required-style {
  color: var(--ui-danger);
  font-size: var(--contentSmallB);
}
