.file-area[data-disabled='true'] {
  background: rgba(0, 0, 0, 0.2);
}
.file-area {
  width: 100%;
  border: 2px dashed #48acb2;
  position: relative;
  height: 188px;
  border-radius: 10px;

  input[type='file'] {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
    opacity: 0;
  }
  > section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  > section > i {
    color: #48acb2;
    font-size: 100px;
    display: block;
    font-weight: 100;
  }
  &:hover {
    border: 1px solid var(--ui-blue);
    > section > i {
      color: var(--ui-blue);
    }
  }
}
