.chip {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 50px;
  font: var(--contentMediumB);
  &-active {
    // border: 1px solid var(--ui-blue);
    // background: var(--ui-blue);
    // color: var(--ui-light);
    color: var(--ui-blue);
  }
  &-disabled {
    // border: 1px solid var(--theme-color);
    // background: var(--theme-color);
    // color: var(--ui-light);
    color: var(--ui-danger);
  }
}
