.outlet-container {
  padding-top: 60px;
  display: flex;
  gap: 10px;
  background-color: rgb(238, 238, 238);
  min-height: calc(100vh);
  > section {
    padding: 10px;
    flex: 0 0 auto;
    width: 91.66666667%;
    margin-right: auto;
    margin-left: auto;
  }
}

.main-container {
  --sec-gap: 1.5rem 2rem;
  background: var(--ui-light);
  min-height: calc(100vh - 80px);
  border-radius: 10px;
  padding: var(--sec-gap);
}
