.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-transform: capitalize;
  > i {
    font-size: 30px;
    display: inline-block;
  }
  > h2 {
    display: inline-block;
  }
}

.header-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
