.button {
  --btn-border: var(--theme-color);
  --btn-color: #fff;
  --btn-svg-bg: var(--ui-light);
  --btn-radius: 10px;
  --btn-bg: var(--theme-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 20px;
  transition: all 0.4s;
  text-transform: capitalize;
  font: var(--contentMediumB);
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  cursor: pointer;
  color: var(--btn-color);
  border: 1px solid var(--btn-border);
  background: var(--btn-bg);
  border-radius: var(--btn-radius);
  margin: var(--btn-margin);
  svg {
    fill: var(--btn-svg-bg);
    width: 15px;
    margin: 0 10px;
    path: {
      fill: var(--btn-svg-bg);
    }
  }
}
.button-width-full {
  width: 100%;
}

.btn-primary {
  --btn-color: var(--ui-dark);
  --btn-bg: var(--ui-light);

  &-round {
    --btn-color: var(--ui-dark);
    --btn-bg: var(--ui-light);
    border-radius: 50px;
    padding: 5px 10px;
  }
}
.btn-tertiary {
  --btn-color: var(--ui-dark);
  --btn-bg: #ffd213;
  --btn-border: #ffd213;
}
.btn-secondary {
  --btn-color: var(--ui-light);
  --btn-bg: var(--ui-blue);
  --btn-border: var(--ui-blue);
  &-round {
    --btn-color: var(--ui-light);
    --btn-bg: var(--ui-blue);
    --btn-border: var(--ui-blue);
    border-radius: 50px;
    padding: 5px 10px;
  }
}

.button:disabled {
  --btn-bg: var(--ui-neutral);
  --btn-border: var(--ui-neutral);
  opacity: 0.5;
}
