.container {
  margin-bottom: 10px;
}
.container input {
  outline: none;
  border: 0px;
  border-radius: 10px;
  font: var(--contentMedium);
  font-weight: 400;
  line-height: 16px;
  color: #131d3b;
  width: 100%;
  background: none;
  padding: 0 5px 0 5px;
  min-height: 40px;
  flex: 1;
  display: flex;
}

.input-container {
  position: relative;
  border: 1px solid var(--ui-light-neutral);
  border-radius: 5px;
  display: flex;
  align-items: center;
  background: var(--ui-light);
  padding: 0 5px;
  min-height: 40px;
  margin-bottom: 5px;
  gap: 5px;
  flex-wrap: wrap;
}

.container input::placeholder {
  font: var(--contentMedium);
  font-weight: 400;
  line-height: 16px;
  color: var(--ui-neutral);
  text-transform: capitalize;
}

.container i,
.container svg {
  color: var(--ui-neutral);
  cursor: pointer;
  margin: 0 5px;
  font-size: 25px;
  width: 25px;
  overflow: hidden;
}

.container svg {
  width: 20px;
  path {
    fill: var(--ui-neutral);
  }
}

.required-style {
  color: var(--ui-danger);
  font-size: var(--contentSmallB);
}

.helper-text {
  color: var(--ui-blue);
  font: var(--contentVerySmall);
  margin-left: 5px;
}
