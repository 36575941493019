.label-style,
.error-style {
  margin: 5px 0;
  font: var(--contentSmall);
  color: var(--ui-danger);
  min-height: 18px;
}

.error-style {
  font: var(--contentMediumB);
}
