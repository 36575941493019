.layout-container {
  display: flex;
  background-color: #ffffff;
  min-height: calc(100vh);

  > .main-container {
    width: 100%;
  }
}

.content-container {
  padding: 40px;
  flex: 0 0 auto;
  width: calc(100vw - 315px);
  height: calc(100% - 90px);
  margin-right: auto;
  margin-left: auto;
  border-radius: 40px 0 0 0;
  background: #f9f9f9;
  overflow-x: hidden;
}

@media screen and (max-width: 1200px) {
  .content-container {
    width: 100%;
    border-radius: 0 0 0 0;
  }
}

@media screen and (max-width: 526px) {
  .content-container {
    padding: 20px;
  }
}
